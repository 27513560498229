import settings from '@locale-config';
import { Translations } from './translations';
import { ErrorResponse, ListProductsResponse } from './types';

const locale = import.meta.env.VITE_APP_LOCALE?.toLowerCase();
const attractEndpoint = import.meta.env.VITE_APP_API_ATTRACT_URL;
const country = settings.country.toLowerCase();

export const getData = async (amount: number) => {
  let errorMessage = '';
  const urls = [
    `${attractEndpoint}/v1/calculations/${country}/${amount}`,
    `${attractEndpoint}/attract/v1/translations/${locale}/monthlypay`,
  ];
  const calculationPromise = fetch(urls[0])
    .then(async (res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          errorMessage = error?.error;
        });
      }
      // const { products }: ListProductsResponse = await res.json();
      return res.json();
    })
    .catch((error: ErrorResponse) => {
      if (error !== undefined) errorMessage = error;
      else errorMessage = 'Could not fetch calculation.';
      return null;
    });
  const translationsPromise = fetch(urls[1])
    .then(async (res) => {
      const data = await res.json();
      return data.data as Translations;
    });

  const [calculations, trans] = await Promise.all([
    calculationPromise,
    translationsPromise,
  ]).catch(() => {
    throw Error('Could not fetch translation.');
  });
  return [calculations, trans, errorMessage] as const;
};

export const getUsData = async () => {
  let errorMessage = '';
  const urls = [
    `${attractEndpoint}/v1/listproducts/${country}`,
    `${attractEndpoint}/attract/v1/translations/${locale}/monthlypay`,
  ];

  const productPromise = fetch(urls[0])
    .then(async (res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          errorMessage = error?.error;
        });
      }
      const { products }: ListProductsResponse = await res.json();
      return products;
    })
    .catch((error: ErrorResponse) => {
      if (error !== undefined) errorMessage = error;
      else errorMessage = 'Could not fetch calculation.';
      return null;
    });

  const translationsPromise = fetch(urls[1])
    .then(async (res) => {
      const data = await res.json();
      return data.data as Translations;
    });

  const [products, trans] = await Promise.all([
    productPromise,
    translationsPromise,
  ]).catch(() => {
    throw Error('Could not fetch translation.');
  });
  return [products, trans, errorMessage] as const;
};
