import { Settings } from '.';

const settings: Settings = {
  country: 'US',
  noOfDecimals: 2,
  currencyPrefix: '$',
  currencySuffix: '',
  includeTncHeader: false,
  isBread: true,
  calculationLineOrder: [],
  hideLink: false,
};

export default settings;
